/* Home Component*/
.home {
    a {
        text-decoration: none;
        color: $linkColor;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    p {
        margin-bottom: 2rem;
    }

    .introduction {
        background-color: var(--surface-a);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 147px 30px 167px 50px;
        color: #ffffff;
        position: relative;

        &.introduction-dark {
            color: var(--text-color);

            .introduction-promo {
                background-image: linear-gradient(
                    90deg,
                    #445c72 0%,
                    transparent 100%
                );
            }
        }

        .introduction-promo {
            display: inline-block;
            margin-left: -50px;
            margin-bottom: 20px;
            padding: 10px 50px 10px 175px;
            font-size: 1.5rem;
            background-image: linear-gradient(
                90deg,
                #445c72 0%,
                transparent 100%
            );
            position: relative;
            color: #ffffff;
            font-weight: 700;

            img {
                position: absolute;
                height: 75px;
                left: 50px;
                top: -12px;
            }
        }

        .introduction-title {
            font-weight: 400;
            margin-bottom: 5px;
            font-size: 24px;
        }

        .introduction-subtitle {
            font-weight: 700;
            margin-bottom: 40px;
            margin-top: 0;
            font-size: 24px;
        }

        .introduction-devices {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 60%;
        }
    }

    .features {
        background-color: var(--surface-b);
        text-align: center;
        padding: 2rem;

        .p-col-12 {
            padding: 1rem;
        }

        .feature-card {
            background-color: var(--surface-e);
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
                0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            height: 100%;
            border-radius: 3px;

            .feature-card-detail {
                padding: 0 2rem 2rem 2rem;
            }

            p {
                margin-bottom: 0;
            }
        }

        img {
            width: 100%;
        }

        .feature-name {
            font-weight: 700;
            font-size: 16px;
            margin: 1rem 0;
            display: inline-block;
        }
    }

    .whouses {
        background-color: #34495e;
        color: #eaecee;
        text-align: center;
        padding: 2rem;

        img {
            height: 30px;
            width: 100%;
        }

        .p-grid > div {
            padding: 2rem 0.5rem;
        }
    }

    .templates {
        background-color: var(--surface-b);
        text-align: center;
        padding: 2rem;
        border-bottom: 1px solid var(--surface-d);

        img {
            width: 100%;
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
                0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        }
    }

    .primereact-designer {
        background-color: var(--surface-a);
        color: var(--text-color);
        padding: 2rem;

        img {
            width: 100%;
            margin-bottom: 30px;
        }

        h4 {
            text-align: center;
        }
    }

    .prosupport {
        border-bottom: 1px solid var(--surface-d);
        background-color: var(--surface-b);
        padding: 2rem;
        color: var(--text-color);

        img {
            margin-top: 10px;
        }

        .p-md-6:last-child {
            text-align: center;
        }

        .action-button {
            display: inline-block;
            margin-top: 1rem;
        }
    }
}
