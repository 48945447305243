.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@mixin dialog-maximized {
    .p-dialog-maximized {
        -webkit-transition: none !important;
        transition: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        width: 100vw !important;
        max-height: 100%;
        height: 100%;
        max-width: 100vw !important;
        iframe {
            width: 100% !important;
            height: 100vh !important;
        }
    }
}

.p-dialog-default {
    position: relative;
    width: auto;
    margin: 0.5rem;
}
@media screen and (min-width: $xs) {
    .select-option-custom {
        max-width: 19rem !important;
    }
    .layout-content {
        padding-top: var(--height-header-footer) !important;
    }
    .action-table {
        width: auto !important;
    }
    .action-tree-table {
        width: auto !important;
    }
    .p-dialog-xs {
        max-width: 300px !important;
    }
    @include dialog-maximized();
    .layout-content {
        margin-left: 0;
        .content-section {
            min-height: var(--min-height-content);
            padding: 1.6rem !important;
        }
        .card {
            padding: 1.3rem;
        }
    }
}
@media screen and (min-width: $sm) {
    .container {
        max-width: 540px;
    }
    .p-dialog-default {
        max-width: 500px !important;
        margin: 1.75rem auto;
    }
    .p-dialog-sm {
        max-width: 300px !important;
    }
    @include dialog-maximized();
}
// tablet
@media screen and (min-width: $md) {
    .select-option-custom {
        max-width: 22rem !important;
    }
    .container {
        max-width: 720px;
    }
    .p-dialog-md {
        max-width: 500px !important;
    }

    @include dialog-maximized();
    .p-datatable {
        .p-datatable-tbody tr td {
            border: solid 1px var(--surface-d) !important;
        }
        .p-datatable-thead tr th {
            background-color: var(--surface-a) !important;
            border: solid 1px var(--surface-d) !important;
            // border-bottom: solid 1px var(--surface-d) !important;
        }
        .p-datatable-tbody > tr.p-highlight {
            background-color: var(--surface-atbody) !important;
            color: var(--black);
        }
        // .p-datatable-tbody > tr.p-highlight {
        //     background-color: var(--surface-atbody) !important;
        //     color: var(--color-black);
        // }
    }
}
// laptop
@media screen and (min-width: $lg) {
    .action-table,
    .action-tree-table {
        width: 14rem !important;
    }
    .menu-customer {
        .p-menubar-root-list {
            .p-menuitem {
                .p-menuitem-link {
                    .p-menuitem-icon {
                        color: #fff !important;
                        font-weight: 500;
                    }
                    .p-submenu-icon {
                        color: #fff !important;
                    }
                    .p-menuitem-text {
                        color: #000;
                    }
                }
            }
        }
        .p-menubar {
            .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
                .p-menuitem-text {
                    color: #fff !important;
                    font-weight: 600;
                }
            }
        }
    }
    .container {
        max-width: 960px;
    }
    .p-dialog-lg,
    .p-dialog-xl {
        max-width: 800px !important;
    }
    @include dialog-maximized();
}
@media screen and (min-width: $xl) {
    .container {
        max-width: 1140px;
    }
    .p-dialog-xl {
        max-width: 1140px !important;
    }
    @include dialog-maximized();
}

// responsive table
@media screen and (max-width: $sm) {
    *:not(h1, h2, h3, h4, h5, h6, svg, i) {
        font-size: 0.9rem !important;
    }
    .p-paginator {
        .p-dropdown,
        .p-inputwrapper,
        .p-inputwrapper-filled {
            margin-left: 0 !important;
            margin-bottom: 0.5rem !important;
        }
        button {
            margin-bottom: 0.5rem !important;
        }
    }
    .p-toolbar-group-left {
        width: 100% !important;
    }
    .p-toolbar-group-right {
        width: 100% !important;
    }
    .p-paginator {
        button {
            min-width: 2rem !important;
            height: 2rem !important;
        }
        .p-dropdown {
            height: 2rem !important;
        }
    }
    .p-datatable {
        &.p-datatable-responsive-demo {
            .stt-table {
                width: 100% !important;
                text-align: left !important;
            }
            .p-datatable-tbody > tr.p-highlight {
                background-color: var(--surface-atbody) !important;
                color: var(--black);
            }
            .p-column-title,
            .p-d-flex .p-column-title {
                width: 50% !important;
                min-width: 50% !important;
                margin-right: 0 !important;
            }
            tr > td {
                border-width: 1px;
            }
            td {
                // border-bottom: solid 1px var(--surface-d) !important;
            }
            .checkbox-table {
                &::before {
                    content: "Chọn ";
                    font-weight: bold;
                    width: 50% !important;
                    display: inline-block;
                    margin: -0.4em 0 -0.4em -0.4rem;
                }
            }
            .action-table {
                width: 100% !important;
                // text-align: left !important;
            }
            tr {
                border-left: solid 1px var(--surface-d) !important;
                border-right: solid 1px var(--surface-d) !important;
                &:first-child {
                    border-top: solid 1px var(--surface-d) !important;
                }
                &:nth-child(odd) {
                    background: var(--surface-c);
                }
            }
        }
        @mixin block-ellipsis($row) {
            display: -webkit-box;
            -webkit-line-clamp: $row;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .max-line-3 {
            @include block-ellipsis(5);
            span {
                @include block-ellipsis(5);
            }
        }
    }
    .fc-toolbar {
        justify-content: center;
        flex-direction: column;
    }
    .fc-center {
        margin-bottom: 0.2rem;
        margin-top: 0.2rem;
    }
    .p-toast {
        width: 20rem;
        right: 0 !important;
    }
    .chart-home {
        display: none;
    }
    .user-css {
        .p-dialog-content {
            overflow-y: auto !important;
        }
    }
    .login-wrap {
        .login-html {
            min-width: 95vw !important;
            min-height: 98vh !important;
            padding: 8.5rem 1.5rem;
            max-width: 95vw;
            max-height: 98vh;
        }
        .logo-login {
            width: 18rem !important;
            height: auto;
        }
    }
    .layout-content {
        .content-section {
            padding: 0.8rem 0.6rem !important;
        }
        .card {
            padding: 1rem !important;
            margin-bottom: 0;
        }
    }
    .home {
        .features {
            padding: 0.8rem 0.6rem !important;
            .p-col-12 {
                padding: 0.5rem;
            }
        }
        .p-grid {
            margin-left: 0;
        }
    }
}
