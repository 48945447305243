$colorTextMenu: #fff;
$colorIconMenu: #000;
@mixin colorMenu($color, $font) {
    color: $color !important;
    font-weight: $font !important;
}

.menu-customer {
    width: 100%;
    padding-bottom: 10px;
    .p-menubar {
        padding-bottom: 0px;
        padding-left: 0;
        padding-right: 0;
        background-color: var(--surface-a);
        border: none;
        .p-submenu-list {
            right: 0;
        }
        .p-menubar-root-list .p-menuitem .p-submenu-list .p-menuitem-link .p-menuitem-icon {
            @include colorMenu($colorIconMenu, 500);
        }
    }
    .p-menubar-root-list {
        width: 100%;
    }

    .p-connected-overlay-enter-done {
        display: block;
    }
    .p-connected-overlay-exit {
        display: none;
    }
    .p-menubar {
        .menu-admin {
            right: 0;
        }
    }
}
.MenubarDemoMobile {
    display: none;
}
@media screen and (max-width: 960px) {
    .menu-customer{
        display: none;
    }
    .MenubarDemoMobile {
        display: block;
        .button-menu {
            color: var(--white);
            .pi {
                font-size: 1.5rem !important;
            }
        }
        .p-panelmenu-panel {
            .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
                background: none;
            }
            .p-panelmenu-header {
                a {
                    background: none;
                    border: none !important;
                    border-radius: 0 !important;
                }
            }
            .p-panelmenu-header > a:focus {
                box-shadow: -1px -1px var(--surface-a), -2px -2px var(--surface-a), -3px -3px var(--surface-a),
                    -4px -4px var(--surface-a), -5px -5px var(--surface-a), -6px -6px var(--surface-a),
                    -7px -7px var(--surface-a), -8px -8px var(--surface-a);
            }
            .p-highlight > a {
                box-shadow: -1px -1px var(--surface-a), -2px -2px var(--surface-a), -3px -3px var(--surface-a),
                    -4px -4px var(--surface-a), -5px -5px var(--surface-a), -6px -6px var(--surface-a),
                    -7px -7px var(--surface-a), -8px -8px var(--surface-a);
                transition: 0.5;
                animation: shadow-pop-tl 0.3s;
            }
            .p-highlight:not(.p-disabled) > a:hover {
                border: none;
                background: none;
            }

            .p-panelmenu-content {
                border: none !important;
                border-radius: 0px;
                border-bottom: thin solid var(--surface-a);
            }
            .p-panelmenu-content {
                padding-left: 2rem;
            }
        }
    }
}
@keyframes shadow-pop-tl {
    0% {
        box-shadow: 0 0 var(--surface-a), 0 0 var(--surface-a), 0 0 var(--surface-a), 0 0 var(--surface-a),
            0 0 var(--surface-a), 0 0 var(--surface-a), 0 0 var(--surface-a), 0 0 var(--surface-a);
        transform: translateX(0) translateY(0);
    }
    100% {
        box-shadow: -1px -1px var(--surface-a), -2px -2px var(--surface-a), -3px -3px var(--surface-a),
            -4px -4px var(--surface-a), -5px -5px var(--surface-a), -6px -6px var(--surface-a),
            -7px -7px var(--surface-a), -8px -8px var(--surface-a);
        transform: translateX(8px) translateY(8px);
    }
}
