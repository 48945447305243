.UploadFile {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    input[type="file"] {
        display: none;
    }
    .custom-file-upload {
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        background: #007bff;
        border: 1px solid #007bff;
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
        border-radius: 4px;
        &:hover {
            background: #0069d9;
            color: #ffffff;
            border-color: #0069d9;
        }
    }
    .file-preview {
        .p-text-file {
            font-weight: 500;
            i {
                font-weight: bold;
            }
            svg {
                font-weight: bold;
            }
        }
    }
    .view-pdf{
        .p-dialog-content{
            overflow: auto;
        }
    }
    .upload-img-preview {
        width: 100%;
        height: 100%;
    }
}
