@media screen and (max-width: 960px) {
    .layout-wrapper.layout-news-active {
        .layout-content {
            padding-top: 180px;
        }

        .layout-sidebar {
            top: 0;
            height: 100%;
        }

        .layout-news-button {
            opacity: 0;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            margin: 0;
            top: 0;
            left: 0;
        }
    }

    .layout-topbar {
        height: 110px;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;

        .menu-button {
            display: block;
        }

        .logo {
            img {
                width: 150px;
            }
        }

        .app-theme {
            margin-left: 0;
            margin-right: 23px;
        }

        .topbar-menu {
            background-color: var(--surface-a);
            width: 100%;
            height: 40px;
            margin: 0;
            border-top: 1px solid var(--surface-d);

            > li {
                height: 40px;
                line-height: 40px;
                width: 25%;

                > a,
                > .p-link {
                    padding-bottom: 0;
                    height: 40px;
                    line-height: 38px;
                    width: 100%;
                    font-size: 14px;
                    min-width: auto;
                }

                &.topbar-submenu > ul {
                    top: 40px;
                }
            }
        }
    }

    .layout-sidebar {
        top: 0;
        z-index: 999;
        height: 100%;
        transform: translateX(-100%);

        &.active {
            transform: translateX(0);
        }
    }

    .layout-content {
        margin-left: 0;
        padding-top: 110px;

        .content-section {
            &.introduction {
                flex-direction: column;

                .app-inputstyleswitch {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    .layout-mask {
        background-color: rgba(0, 0, 0, 0.1);

        &.layout-mask-active {
            z-index: 998;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: background-color 0.5s;
        }
    }

    .home {
        .introduction > div {
            width: 100%;
        }

        .features > div {
            width: 100%;
        }

        .whouses > div {
            width: 100%;
        }

        .prosupport > div {
            width: 100%;
        }
    }

    .layout-config {
        .layout-config-button {
            left: auto;
            right: -52px;
        }

        &.layout-config-active {
            width: 100%;
        }
    }

    .blocked-scroll {
        overflow: hidden;
    }
}

@media screen and (max-width: 640px) {
    .layout-wrapper.layout-news-active {
        .topbar-menu {
            > li {
                &.topbar-submenu {
                    > ul {
                        top: 180px;
                    }
                }
            }
        }
    }

    .layout-topbar {
        .topbar-menu {
            > li {
                &.topbar-submenu {
                    position: static;

                    > ul {
                        top: 110px;
                        position: fixed;
                        right: auto;
                        left: 0;
                        width: 100vw;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .layout-news .layout-news-container img.layouts-news-mockup-image {
        width: 95%;
    }
}
