.user-css {
    .p-dialog-content {
        overflow-y: inherit !important;
    }
    // .select-option-custom{
    //     width: 18vw;
    // }
    // @media screen and (max-width: 960px) {
    //     .select-option-custom{
    //         width: 9rem;
    //     }
    // }
}


