$font-weight: 500;
@import "../../assets/style/custom/_overrides";
.RoomMeetingDialogAddEdit{
    .p-dialog-content {
        overflow-y: overlay !important;
        position: relative;
        .add-btn {
            position: absolute;
            top: 1rem;
            right: 2.7rem;
        }
        min-height: 33rem;
    }
}
.DetailRoomMeeting{
    .p-dialog-content {
        min-height: 22rem !important;
    }
    .content__detail {
        &__icon {
            svg {
                font-weight: bold;
                font-size: 1.2rem;
            }
        }
        &__title {
            font-weight: $font-weight;
            svg {
                color: var(--primary);
            }
        }
        &__value {
            &--bold {
                font-weight: $font-weight;
            }
        }
    }
}

.cs-input-color {
    > input {
        width: 2.5rem!important;
        height: 2.5rem!important;
    }
    display: block;
}
